import DOMPurify from 'dompurify';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import React,{useCallback} from 'react';


const checkToken = async (params) => {
    try {
      const response = await axios.get(process.env.REACT_APP_CHECK_TOKEN, {
          params});
      return response.data;
    } catch (error) {
        toast.error('람다에서의 통신 오류..', error);
    }
  };

const CustomComponent = React.memo(({ msg }) => {
const cleanHTML = DOMPurify.sanitize(msg.text);
const hasH1 = /<h1[^>]*>/.test(cleanHTML);
const h1Content = cleanHTML.match(/<h1[^>]*>(.*?)<\/h1>/)?.[1];


const handleButtonClick = useCallback(async () => {
    const accessToken = sessionStorage.getItem('accessToken');
    const refreshToken = sessionStorage.getItem('refreshToken');
    const loginType = sessionStorage.getItem('loginType');
    const params = { accessToken, refreshToken, loginType };
    const result = await checkToken(params);

    if (!result) {
        toast.error('존재하지 않는 사용자에요...');
    } else {
        const store = async () => {
            try {
                const output = await axios.get(process.env.REACT_APP_STORE_RECIPE, {
                    params: {
                        sub: result.sub,
                        message: h1Content,
                    }
                });
                const out = output.data.message;
                return out;
            } catch (err) {
                if (err.response && err.response.status === 500) {
                    throw new Error('이미 저장된 레시피입니다!');
                } else if (err.response && err.response.status === 501) {
                    throw new Error('레시피는 최대 5개까지만 저장할 수 있습니다!');
                } else {
                    throw new Error('에러가 발생하여 저장하지 못했습니다..');
                }
            }
        };
        toast.promise(
            store(),
            {
                loading: '저장 중입니다...',
                success: (data) => {
                    return data;
                },
                error: (err) => {
                    return err.message;
                }
            }
        );
    }
    }, [h1Content]);

    return (
    <div>
    <div dangerouslySetInnerHTML={{ __html: cleanHTML }} />
    {hasH1 && (
        <div className="save-container">
            <img 
              src="/recipe.png" 
              alt="Save Icon" 
              onClick={handleButtonClick} 
              style={{ cursor: 'pointer', width: '20px', height: '20px', marginRight: "8px",marginTop:"1%", borderRadius: 0,background:"none"}} 
          />
            <h4 style={{ marginTop: "1%", cursor: 'pointer' }} onClick={handleButtonClick}>레시피 저장하기</h4>
        </div>
    )}
    </div>
    );
});


export default CustomComponent;