import React, { useEffect, useState, useRef, useCallback } from "react";
import './Recipe.css';
import './Recipe_mobile.css';
import { useNavigate} from 'react-router-dom';
import axios from "axios";
import { BrowserView, MobileView } from "react-device-detect";
import CustomTypingEffect from './CustomEffect';
import Fade from 'react-reveal';
import { toast } from "react-hot-toast";

const View = ({ mode, settingMode }) => {
    let navigate = useNavigate();
    const [userInfo, setUserInfo] = useState(null);
    const [messages, setMessages] = useState([]);
    const cancelTokenSource = useRef(null);
    const [menuVisible,setMenuVisible] = useState(false);
    const initialRender = useRef(true);
    const messageEndRef = useRef(null);
    const [h1Content, setH1Content] = useState('');
    const [selectedIcon, setSelectedIcon] = useState(null);
    const [settingMenuVisible, setSettingMenuVisible] = useState(false);
    const [recipes, setRecipes] = useState([]);
    const [recommendations, setRecommendations] = useState([]);
    const [selectedRecipe, setSelectedRecipe] = useState(null);
    const rightHalfRef = useRef(null);
    const [isEditMode, setIsEditMode] = useState(false);
    const [clickedRecipe, setClickedRecipe] = useState(null);
    const recipeContentRef = useRef(null);
    const [emotion_text,set_emotion] = useState('');

const handleSignOut = () => {
    const logType = sessionStorage.getItem('loginType')
    if(logType==='regular'){
        sessionStorage.clear();
        navigate('/');
    }
    else if(logType==='google'){
        sessionStorage.clear();
        navigate('/');
    }else{
        navigate('/');
    }
};

const scrollToBottom = useCallback(() => {
    try {
        if (rightHalfRef.current) {
            rightHalfRef.current.scrollTop = rightHalfRef.current.scrollHeight;
        } else {
            toast.error('비어있어용..');
        }
    } catch (error) {
        toast.error('스크롤 오류!', error);
    }
}, []);


const handleIconClick = (icon) => {
    setSelectedIcon(icon);
    switch (icon){
        case 'chat':
            navigate('/chating');
            break;
        case 'archive':
            navigate('/Recipe')
            break;
        case 'settings':
            setSettingMenuVisible(prev => !prev);
            break;
        case 'password':
            navigate('/password')
        default:
            break;
    }
};
const toggleMenu = () => {
    setMenuVisible(!menuVisible); 
};
const closeMenu = () => {
    setMenuVisible(false); 
};
useEffect(() => {
    const accessToken = sessionStorage.getItem('accessToken');
    const refreshToken = sessionStorage.getItem('refreshToken');
    const loginType = sessionStorage.getItem('loginType');

    const recieveRecipes = async () => {
        const axiosRecipe = async() =>{
            const response = await axios.get(process.env.REACT_APP_GET_RECIPE, {
                params: {
                    accessToken : accessToken,
                    refreshToken : refreshToken,
                    loginType : loginType,
                }
            });
            return response.data;
        }
        toast.promise(
            axiosRecipe(),
            {
                loading: '레시피 목록을 불러오는 중입니다...',
                success: (data) => {
                    setRecipes(data.title || []);
                    setRecommendations(data.recommend || []);
                    set_emotion(data.writing);
                    return '저장된 레시피 목록을 불러왔습니다!';
                },
                error: '불러오던중 오류가 발생했습니다...'
            }
        );
    };
    recieveRecipes();
}, []);

const RecipeClick = async (event, isRecommendation = false) => {
    const recipeName = event.target.innerText;
    // setClickedRecipe(recipeName);
    // setSelectedRecipe(null);
    // scrollToBottomMobile();
    if (clickedRecipe === recipeName) {
        setClickedRecipe(null);
        setSelectedRecipe(null);
        return;
    }
    setClickedRecipe(recipeName);
    setSelectedRecipe(null);
    const selectR = async () => {
        const response = await axios.get(process.env.REACT_APP_LAMBDA_AI_RECIPE, { 
            params: { name: recipeName },
            headers: { 'Content-Type': 'application/json' }
        });
        return response.data;
    };

    toast.promise(
        selectR(),
        {
            loading: '레시피를 불러오는 중입니다...',
            success: (data) => {
                setSelectedRecipe({
                    text: data.recipe,
                    showSaveOption: !isRecommendation
                });
                return '레시피를 성공적으로 불러왔습니다!';
            },
            error: '아직 레시피가 준비되지 않았습니다...'
        }
    );
};

const toggleEditMode = () => {
    setIsEditMode(prevMode => !prevMode);
};

const scrollToBottomMobile = useCallback(() => {
    try {
        if (recipeContentRef.current) {
            recipeContentRef.current.scrollTop = recipeContentRef.current.scrollHeight;
        } else {
            toast.error('비어있어용..');
        }
    } catch (error) {
        toast.error('스크롤 오류!', error);
    }
}, []);

const RemoveRecipe = async(recipe) =>{
        const title = recipe;
        const accessToken = sessionStorage.getItem('accessToken');
        const refreshToken = sessionStorage.getItem('refreshToken');
        const loginType = sessionStorage.getItem('loginType');
    const remove = async()=>{
    const response = await axios.get(process.env.REACT_APP_LAMBA_DELETE_RECIPE,{
        params :{
            accessToken : accessToken,
            refreshToken : refreshToken,
            loginType : loginType,
            title : title,
        }
    });
    setRecipes(prevRecipes => prevRecipes.filter(r => r !== recipe));
}
    toast.promise(
        remove(),{
            loading:"삭제하는 중입니다....",
            success:"삭제가 완료되었습니다!",
            error: (err)=>{
                if(err.response){
                    switch (err.response.status){
                        case 501:
                            return "레시피는 최대 5개 까지만 저장이 가능합니다!"
                    }
                }else return "삭제하는 도중 오류가 발생했습니다!"
            }
        }
    )
}

const addRecipe = (newRecipe) => {
    setRecipes((prevRecipes) => {
        if (prevRecipes.includes(newRecipe)) {
          return prevRecipes;
        } else {
          return [...prevRecipes, newRecipe];
        }
      });
  };
return (
<div>
<BrowserView>
<Fade  delay = {0}>
    <div className="chat-container">
        <div className="margin-1"></div>
        <div className="left-half">
            <img src="icon_reverse.png" className="logo1" alt="logo"></img>
            <div className={`icon_chat_Recipe ${selectedIcon === 'chat' ? 'selected' : ''}`}onClick={() => handleIconClick('chat')}>
                <img src="icon_chat.png" alt="chat" style={{ cursor: 'pointer' }} width="50vh"></img>
            </div>
            <div className={`icon_archive_Recipe ${selectedIcon === 'archive' ? 'selected' : ''}`}onClick={() => handleIconClick('archive')}>
                <img src="icon_archive.png" alt="archive" style={{ cursor: 'pointer' }} width="50px"></img>
            </div>
            <div className={`icon_settings ${selectedIcon === 'settings' ? 'selected' : ''}`}onClick={() => handleIconClick('settings')}>
                <img src="icon_settings.png" alt="setting" style={{ cursor: 'pointer' }} width="50px"></img>
            </div>
            {settingMenuVisible && (
                <Fade delay = {3}>
                <div className="setting-menu">
                <div className="icon_mode">
                <label className="switch">
                <span className="slider-label">테마</span>
                    <input type="checkbox" checked={mode} onChange={settingMode} />
                    <span className="slider round"></span>
                </label></div>
                    <button className={`password_menu ${selectedIcon === 'password' ? 'selected' : ''}`}onClick={() => handleIconClick('password')}>
                        비밀번호 재설정</button></div>
                </Fade>
            )}
            <div className="icon_exit">
                <img src="icon_exit.png" alt="exit" style={{ cursor: 'pointer' }} onClick={handleSignOut} width="55px"></img>
            </div>
        </div>
        <div className="middle-container">
            <div className="store-recipe-title">
                <h2>저장한 레시피</h2><div className="edit"onClick={toggleEditMode}><h6>{isEditMode ? '편집 완료' : '편집'}</h6></div>
                <hr className="line-title"></hr>
            </div>
            <div className="store-recipe-menu">
            {recipes.length === 0 ? (
                <h5>아직 저장된 레시피가 없습니다...</h5>
            ) : (
        recipes.map((recipe, index) => (
            <div key={index} className="recipe-item-container">
            <h5 className="recipe-item-store" onClick={(event) => RecipeClick(event, true)}>{recipe}</h5>
            {isEditMode && (
            <button className="remove-recipe-button" onClick={() => RemoveRecipe(recipe)}>
               <img src="delete.svg" alt="제거" className="minus-icon"/>
            </button>
        )}
        </div>
            ))
        )}
        </div>
            <div className="recommend-recipe">
                <h2>추천 레시피</h2>
                <div className="emotion-text">{emotion_text}</div>
                <hr className="line-title-2"></hr>
            </div>
            <div className="store-recipe-menu">
            {recommendations.length === 0 ? (
                <h5>추천 레시피가 없습니다...</h5>
            ) : (
                recommendations.map((recipe, index) => (
                    <h5 key={index} className="recipe-item" onClick={(event) => RecipeClick(event, false)}>{recipe}</h5>
                ))
            )}
        </div>
        </div>
        <div className="right-half-Recipe" ref={rightHalfRef}>
        <div className="recipe-container">
        {selectedRecipe && (
            <CustomTypingEffect 
            text={selectedRecipe.text} 
            speed={10} 
            scrollToBottom={scrollToBottom} 
            onComplete={scrollToBottom}
            addRecipe={addRecipe}
            showSaveOption={selectedRecipe.showSaveOption}/>)}</div>
        </div>
        </div>
        </Fade>
            </BrowserView>
        <MobileView>
        <Fade delay = {0}>
        <div className="chat-container-mobile">
        <div className={`overlay ${menuVisible ? 'show' : ''}`} onClick={closeMenu}></div>
            <div className="top-container-mobile">
            <div className="icon_menu_mobile" onClick={toggleMenu}>
                <img src="menu.png" alt="menu" className="menu-icon"/>
            </div>
            <div className="center-text">
            공감 식당
            </div>
            </div>
            <div className={`menu-bar ${menuVisible ? 'show' : ''}`}>
            {}
            <div className="top-menu">
                <div className={`chat-mobile-Recipe ${selectedIcon === 'chat' ? 'selected' : ''}`}onClick={() => handleIconClick('chat')}>
                    <img src="mobile_chat.png"></img>
                    <h2>채팅</h2>
                </div>
                <div div className={`mobile-recipe-Recipe ${selectedIcon === 'recipe' ? 'selected' : ''}`}onClick={() => handleIconClick('archive')}>
                    <img src="mobile_recipe.png"></img>
                    <h2>저장한 레시피 보기</h2>
                </div>
            </div>
            <div className="bottom-section">
            <div className="mobile_setting">
                <img src="mobile_setting.png" alt="set"/>
                <h2>설정</h2>
            </div>
            <div className="icon-exit_mobile" onClick={handleSignOut}>
                <img src="mobile_exit.png" alt="exit"/>
                <h2>로그아웃</h2>
            </div>
            </div>
            {/* 추가 메뉴 아이템들 */}
            </div>
            <hr className="line-title-mobile"></hr>
            <div className="write-mobile">
            <div className="store-recipe-title-mobile">
            <div className="store-text">저장한 레시피</div><div className="edit-mobile"onClick={toggleEditMode}><div className="edit-text">{isEditMode ? '편집 완료' : '편집'}</div>
            </div>
            <div className="store-recipe-menu-mobile">
            {recipes.length === 0 ? (
            <h5>아직 저장된 레시피가 없습니다...</h5>
            ) : (
            recipes.map((recipe, index) => (
            <div key={index} className="recipe-item-container-mobile">
            <div className="recipe-item-wrapper">
            <h5 className="recipe-item-mobile" onClick={(event) => RecipeClick(event, true)}>{recipe}</h5>
            {isEditMode && (
                <button className="remove-recipe-button-mobile" onClick={() => RemoveRecipe(recipe)}>
                    <img src="delete.svg" alt="제거" className="minus-icon" />
                </button>
            )}
            </div> 
            {clickedRecipe === recipe && selectedRecipe && (
            <div className={`recipe-box ${clickedRecipe === recipe ? 'expanded' : ''}`}>
                <div className="recipe-content">
                    <div dangerouslySetInnerHTML={{ __html: selectedRecipe.text }} />
                </div>
            </div>
            )}
            </div>
            ))
            )}
            </div>
        </div>
        
        <div className="recommend-recipe-mobile">
        <hr className="line-title-Recipe"></hr>
            <div className="today-text" >{emotion_text.length===0?(
                <h4>추천 레시피</h4>
            ):(emotion_text)}</div>

            <div className="recommend-recipe-menu-mobile">
            {recommendations.length === 0 ? (
                <h5>추천 레시피가 없습니다...</h5>
            ) : (
            recommendations.map((recipe, index) => (
            <div key={index} className="recommend-recipe-item-container-mobile">
                <div className="recommend-recipe-item-wrapper">
                    <h4 className="recommend-recipe-item-mobile" onClick={(event) => RecipeClick(event, false)}>{recipe}</h4>
                </div>
                {clickedRecipe === recipe && selectedRecipe && (
                    <div className={`recipe-box ${clickedRecipe === recipe ? 'expanded' : ''}`}>
                        <div className="recipe-content">
                            <div dangerouslySetInnerHTML={{ __html: selectedRecipe.text }} />
                        </div>
                    </div>
                )}
                </div>
                ))
            )}
        </div>
        </div>
        </div>
        </div>
        </Fade>
        </MobileView>
        </div>
    );
};

export default View;
