import React,{useState} from 'react';
import Login from './login/login';
import Chating from './chating/Chating';
import Create from './create/Create';
import Password from './password/Password';
import Confirm from './confirm/Confirm' ;
import Recipe from './recipe/Recipe'
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Toaster, toast } from 'react-hot-toast';
const App = () => {
    const [mode,setMode] = useState(false);

    const settingMode = () => {
        setMode(!mode);
    };
return (
    <div className={mode ? 'dark-mode' : 'light-mode'}>
    <BrowserRouter>
        <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/chating" element={<Chating mode={mode} settingMode={settingMode} />} />
            <Route path="/create" element={<Create />} />
            <Route path="/password" element={<Password />} />
            <Route path='/confirm' element={<Confirm />} />
            <Route path='/Recipe' element={<Recipe mode={mode} settingMode={settingMode} />}/>
        </Routes>
    </BrowserRouter>
    <ToastContainer autoClose={2000} />
    <Toaster />
</div>
    );
};

export default App;
