import React, { useEffect, useState, useRef, useCallback } from "react";
import './Chating.css';
import './Chating_mobile.css';
import { useNavigate} from 'react-router-dom';
import axios from "axios";
import { BrowserView, MobileView } from "react-device-detect";
import DOMPurify from 'dompurify';
import CustomTypingEffect from './CustomEffect';
import Fade from 'react-reveal';
import CustomComponent from "./customComponent";
import { toast } from "react-hot-toast";

const View = ({ mode, settingMode }) => {
    let navigate = useNavigate();
    const [userInfo, setUserInfo] = useState(null);
    const [messages, setMessages] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [isSending, setIsSending] = useState(false);
    const cancelTokenSource = useRef(null);
    const [menuVisible,setMenuVisible] = useState(false);
    const initialRender = useRef(true);
    const messageEndRef = useRef(null);
    const [h1Content, setH1Content] = useState('');
    const [selectedIcon, setSelectedIcon] = useState(null);
    const [settingMenuVisible, setSettingMenuVisible] = useState(false);
    const [isTypingEffectComplete, setIsTypingEffectComplete] = useState(true);
    const [placeholderText, setPlaceholderText] = useState("레시피를 알려드려요");
    
const handleSignOut = () => {
    const logType = sessionStorage.getItem('loginType')
    if(logType==='regular'){
        sessionStorage.clear();
        navigate('/');
    }
    else if(logType==='google'){
        sessionStorage.clear();
        navigate('/');
    }else{
        navigate('/');
    }
};

const getAccessTokenFromUrl = () => {
    const hash = window.location.href;
    const match = hash.match(/code=([^&]*)/);
    return match ? match[1] : null;
};

const axiosUserInfo = useCallback(async (code1) => {
    try {
        const loginType = sessionStorage.getItem('loginType');
        if (loginType === 'google') {
            sessionStorage.setItem('code',code1);
        }
        const code = sessionStorage.getItem('code');
        const refreshToken = sessionStorage.getItem('refreshToken');
        const accessToken = sessionStorage.getItem('accessToken');
        const params = {
            loginType: loginType,
            accessToken: accessToken,
            refreshToken: refreshToken,
            code:code,
        }
        const response = await axios.get(process.env.REACT_APP_LAMBDA_LOGIN_GET, {
            params});
        if(loginType==='google'){
            const access_Token = response.data.access_token;
            const refresh_Token = response.data.refresh_token;
            sessionStorage.removeItem('code');
            sessionStorage.setItem('accessToken',access_Token);
            sessionStorage.setItem('refreshToken',refresh_Token);
            toast.success("구글 로그인 완료!")
            navigate('/chating')
        }
    } catch (error) {
        toast.error('Error networkind user info:', error);
    }
}, []);

const sess = useCallback(() => {
    const code = getAccessTokenFromUrl();
    if (code) {
        axiosUserInfo(code);
    }
}, [axiosUserInfo]);

const scrollToBottom = useCallback(() => {
    try {
        if (messageEndRef.current) {
            requestAnimationFrame(() => {
                messageEndRef.current.scrollIntoView({ behavior: 'smooth' });
            });
        } else {
            toast.error('메세지 마지막이 비었습니다.');
        }
    } catch (error) {
        toast.error('Error scrolling to bottom:', error);
    }
}, []);

//세션스토리지에 메세지를 저장하는 부분
useEffect(() => {
    const savedMessages = JSON.parse(sessionStorage.getItem('messages'));
    if (savedMessages && savedMessages.length > 0) {
        setMessages(savedMessages.map(msg => ({ ...msg, isLoadedFromSession: true })));
    }
}, []);


useEffect(() => {
    scrollToBottom();
}, [messages, scrollToBottom]);

useEffect(() => {
    if (initialRender.current) {
        initialRender.current = false;
        const loginType = sessionStorage.getItem('loginType');
        const sub = sessionStorage.getItem('sub');
        if (loginType === 'google') {
            if (sub) {
                navigate('/chating');
            } else {
                sess();
            }
        } else if (loginType === 'regular') {
            axiosUserInfo();
        }
    }
}, [sess, axiosUserInfo]);

useEffect(() => {
    const placeholderOptions = [
        "Yomi 에게 레시피를 물어보세요!",
        "Yomi 에게 음식을 추천받아보세요!",
        "Yomi 에게 인사해보세요!",
        "Yomi 에게 메세지를 보내보세요!"
    ];

    const changePlaceholder = () => {
        const randomIndex = Math.floor(Math.random() * placeholderOptions.length);
        setPlaceholderText(placeholderOptions[randomIndex]);
    };

    changePlaceholder();
}, []);

const handleInputChange = (e) => {
    setInputValue(e.target.value);
};
const handleIconClick = (icon) => {
    setSelectedIcon(icon);
    switch (icon){
        case 'chat':
            navigate('/chating');
            break;
        case 'archive':
            navigate('/Recipe')
            break;
        case 'settings':
            setSettingMenuVisible(prev => !prev);
            break;
        case 'password':
            toast("비밀번호 변경으로 페이지 이동",{duration:1000})
            navigate('/password')
        default:
            break;
    }
};

const sendMessageLambda = async (message1) => {
    try {
        const re = sessionStorage.getItem('refreshToken');
        const ac = sessionStorage.getItem('accessToken');
        const loginType = sessionStorage.getItem('loginType')
        cancelTokenSource.current = axios.CancelToken.source();
        const response = await axios.get(process.env.REACT_APP_LAMBDA_SEND_GET, {
            params: {
                loginType : loginType,
                accessToken : ac,
                refreshToken : re,
                message: message1,
            },
            cancelToken: cancelTokenSource.current.token
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 404) {
            return { message: '존재하지 않는 레시피입니다....' };
        } else if (error.response.status === 500) {
            return { message: '<h4>서버와의 연결이 원활하지 않습니다.... 잠시 후에 다시 시도해주세요.</h4>'};
        } else if (error.response.status === 501) {
            return { message: '<h4>사용자를 인식하지 못했습니다. 다시 로그인 해주세요!</h4>'};
        }else {
            return { message: '<h4>전송 오류가 발생했습니다!</h4>' };
        }
    }
};

const handleSendClick = async () => {
    if (inputValue.trim() !== '') {
        setIsSending(true);
        setIsTypingEffectComplete(false); // 메시지 전송 시 타이핑 효과 비활성화

        const formattedMessage = inputValue.length > 20
            ? inputValue.match(/.{1,70}/g).join('\n')
            : inputValue;

        const newMessages = [
            ...messages,
            { sender: 'user', text: formattedMessage, isLoadedFromSession: false },
            { sender: 'ai', text: 'spinner', isLoadedFromSession: false }
        ];
        setMessages(newMessages);  
        sessionStorage.setItem('messages', JSON.stringify(newMessages));  
        
        setInputValue('');

        const reply = await sendMessageLambda(formattedMessage);
        if (reply) {
            const updatedMessages = newMessages.filter((msg) => msg.text !== 'spinner').concat({ sender: 'ai', text: reply.message || '오류가 발생했습니다!', isLoadedFromSession: false });
            setMessages(updatedMessages);
            sessionStorage.setItem('messages', JSON.stringify(updatedMessages));
        }
        setIsSending(false);
        setIsTypingEffectComplete(true);
        setH1Content('');
    } else {
        toast.error("입력된 값이 없습니다.");
    }
};
const handleTypingEffectComplete = () => {
    setIsTypingEffectComplete(true);
};

const handleTypingEffectStart = () => {
    setIsTypingEffectComplete(false);
};

const handleCancelClick = () => {
    if (cancelTokenSource.current) {
        cancelTokenSource.current.cancel();
        setMessages((prevMessages) => prevMessages.filter((msg) => msg.text !== 'spinner'));
        setIsSending(false);
        setIsTypingEffectComplete(true);
    }
};
const toggleMenu = () => {
    setMenuVisible(!menuVisible); 
};
const closeMenu = () => {
    setMenuVisible(false); 
};
const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !isSending && isTypingEffectComplete) {
        handleSendClick();
    }
};
return (
<div>
<BrowserView>
<Fade  delay = {0}>
    <div className="chat-container">
        <div className="margin-1"></div>
        <div className="left-half">
            <img src="icon_reverse.png" className="logo1" alt="logo"></img>
            <div className={`icon_chat_chating ${selectedIcon === 'chat' ? 'selected' : ''}`}onClick={() => handleIconClick('chat')}>
                <img src="icon_chat.png" alt="chat" style={{ cursor: 'pointer' }} width="50vh"></img>
            </div>
            <div className={`icon_archive_chating ${selectedIcon === 'archive' ? 'selected' : ''}`}onClick={() => handleIconClick('archive')}>
                <img src="icon_archive.png" alt="archive" style={{ cursor: 'pointer' }} width="50px"></img>
            </div>
            <div className={`icon_settings ${selectedIcon === 'settings' ? 'selected' : ''}`}onClick={() => handleIconClick('settings')}>
                <img src="icon_settings.png" alt="setting" style={{ cursor: 'pointer' }} width="50px"></img>
            </div>
            {settingMenuVisible && (
                <Fade delay = {10}>
                <div className="setting-menu">
                <div className="icon_mode">
                <label className="switch">
                <span className="slider-label">테마</span>
                    <input type="checkbox" checked={mode} onChange={settingMode} />
                    <span className="slider round"></span>
                </label>
            </div>
            <button className={`password_menu ${selectedIcon === 'password' ? 'selected' : ''}`}onClick={() => handleIconClick('password')}>
                        비밀번호 재설정</button></div>
                </Fade>
            )}
            <div className="icon_exit">
                <img src="icon_exit.png" alt="exit" style={{ cursor: 'pointer' }} onClick={handleSignOut} width="55px"></img>
            </div>
        </div>
    <div className="right-half">
        <div className="chat">
        <div className="chating-container">
        <div className="messages">
        {messages.map((msg, index) => (
        <div key={index} className={`message ${msg.sender}`}>
        {msg.sender === 'ai' && msg.text !== 'spinner' ? (
            msg.isLoadedFromSession ? (
                <CustomComponent msg={msg} />
            ) : (
                <CustomTypingEffect
                        text={msg.text}
                        speed={20}
                        scrollToBottom={scrollToBottom}
                        onComplete={() => {
                            scrollToBottom();
                            handleTypingEffectComplete();
                        }}
                        onStart={handleTypingEffectStart} 
                />)) :
            (msg.text === 'spinner' ? (
                <div className="spinner-container">
                    <div className="spinner" />
                </div>) :
                (msg.sender === 'ai' ? (
                    <CustomComponent msg={msg} />
                ) : (
                    (msg.text)
                )
                ))}</div>
            ))}<div ref={messageEndRef} />
                </div>
            </div>
            <div className="input-container">
            <input
            type='text'
            placeholder={placeholderText}
            value={inputValue}
            onKeyPress={handleKeyPress}
            onChange={handleInputChange}
        />
        <button
            onClick={!isTypingEffectComplete ? handleCancelClick : handleSendClick}
            disabled={!isTypingEffectComplete && isSending}
            style={{ background: 'none', border: 'none', padding: 0 }}
        >
            <img
                src={!isTypingEffectComplete ? (mode ? "icon_stop_dark.png" : "icon_stop.png") : (mode ? "icon_send_dark.png" : "icon_send.png")}
                alt="send"
                style={{ cursor: 'pointer' }}
            />
                </button>
                <div className="text-guide">YOMI 는 아직 개발중인 챗봇이에요. 가끔 엉뚱한 답변을 할 수 있어요.</div>
                </div>
                </div>
            </div>
            <div className="margin-bot"></div>
        </div>
        </Fade>
            </BrowserView>
            <MobileView>
            <Fade delay = {0}>
            <div className="chat-container-mobile">
            <div className={`overlay ${menuVisible ? 'show' : ''}`} onClick={closeMenu}></div>
                <div className="top-container-mobile">
                <div className="icon_menu_mobile" onClick={toggleMenu}>
                    <img src="menu.png" alt="menu" className="menu-icon"/>
                </div>
                <div className="center-text">
                공감 식당
                </div>
                </div>
                <div className={`menu-bar ${menuVisible ? 'show' : ''}`}>
                {}
                <div className="top-menu">
                    <div className={`chat-mobile ${selectedIcon === 'chat' ? 'selected' : ''}`}onClick={() => handleIconClick('chat')}>
                        <img src="mobile_chat.png"></img>
                        <h2>채팅</h2>
                    </div>
                    <div div className={`mobile-recipe ${selectedIcon === 'recipe' ? 'selected' : ''}`}onClick={() => handleIconClick('archive')}>
                        <img src="mobile_recipe.png"></img>
                        <h2>저장한 레시피 보기</h2>
                    </div>
                </div>
                <div className="bottom-section">
                <div className="mobile_setting">
                    <img src="mobile_setting.png" alt="set"/>
                    <h2>설정</h2>
                </div>
                <div className="icon-exit_mobile" onClick={handleSignOut}>
                    <img src="mobile_exit.png" alt="exit"/>
                    <h2>로그아웃</h2>
                </div>
                </div>
                {/* 추가 메뉴 아이템들 */}
                </div>
                <hr className="line-bar"></hr>
        <div className="bottom-container-mobile">
            <div className="messages-mobile">
            {messages.map((msg, index) => (
        <div key={index} className={`message-mobile ${msg.sender}`}>
            {msg.sender === 'ai' && msg.text !== 'spinner' ? (
                <div className="ai-message-wrapper">
                    <div className="ai-icon-container">
                <img src="icon.png" alt="AI Icon" className="ai-icon" />
            </div>
            <div className="ai-message-container-mobile">
                {msg.isLoadedFromSession ? (
                    <CustomComponent msg={msg} />
                ) : (
                    <CustomTypingEffect
                    text={msg.text}
                    speed={20}
                    scrollToBottom={scrollToBottom}
                    onComplete={() => {
                        scrollToBottom();
                        handleTypingEffectComplete();
                    }}
                    onStart={handleTypingEffectStart} 
            />)}
            </div>
        </div>) :
        (msg.text === 'spinner' ? (
            <div className="spinner-container-mobile">
                <div className="spinner-mobile" />
            </div>) :
            (<div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(msg.text) }}></div>
            ))}</div>))}<div ref={messageEndRef} />
        </div>
            </div>
            <div className="input-container-mobile">
                <input
            type='text'
            placeholder={placeholderText}
            value={inputValue}
            onKeyPress={handleKeyPress}
            onChange={handleInputChange}
        />
            <button
        onClick={!isTypingEffectComplete ? handleCancelClick : handleSendClick}
        disabled={!isTypingEffectComplete && isSending}
        style={{ background: 'none', border: 'none', padding: 0 }}
        >
        <img
            src={!isTypingEffectComplete ? (mode ? "icon_stop_dark.png" : "icon_stop.png") : (mode ? "icon_send_dark.png" : "icon_send.png")}
            alt="send"
            style={{ cursor: 'pointer' }}
        />
            </button>
            </div>
        </div>
            </Fade>
            </MobileView>
        </div>
    );
};

export default View;

