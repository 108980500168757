import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './login.css';
import '../App.css';
import './mobile_login.css';
import logo from '../icon.png';
import { Link, useNavigate } from 'react-router-dom';
import { Reveal } from 'react-reveal';
import {BrowserView,MobileView} from "react-device-detect";
import { toast } from 'react-hot-toast';

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        sessionStorage.clear();
        const handleKeyPress = (e) => {
            if (e.key === 'Enter') {
                handleSignIn();
            }
        };
        window.addEventListener('keydown', handleKeyPress);
        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, [username, password]);

    const p_find = () => { toast.success("비밀번호 찾기 페이지로 이동합니다."); }
    const c_account = () => { toast.success("회원가입 페이지로 이동합니다."); }
    const e_account = () => { toast.success("이메일 확인페이지로 이동합니다."); }

    const handleSignIn = async () => {
        const signin = async() =>{
        const response = await axios.get(process.env.REACT_APP_COGNITO_LAMBDA_LOGIN,{
            params : { username:username,
                    password:password,
            },
        });
        return response.data;
    };
    toast.promise(
        signin(),
        {
            loading: '로그인 중입니다...',
            success: (data) => {
            const access_token = data.access_token;
            const RefreshToken = data.RefreshToken;
            sessionStorage.setItem('loginType','regular');
            sessionStorage.setItem('accessToken',access_token);
            sessionStorage.setItem('refreshToken',RefreshToken);
            navigate('/chating');
            return "로그인 성공!";
            // toast("저는 레시피를 알려주는 챗봇입니다!\n궁금한 요리가 있으시면 말씀해주세요!",{duration:3000});
        }, error: (err) => {
            if (err.response && err.response.data && err.response.data.error) {
                return err.response.data.error;
            } else {
                return '로그인 실패';
            }
        }
        }
    )
}

    const social_google = () => {
        sessionStorage.setItem('loginType', 'google');
        const googleClientId = process.env.REACT_APP_GOOGLE_CLIENTID;
        const redirectUri = `${window.location.origin}/chating`;
        const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?response_type=code&client_id=${googleClientId}&redirect_uri=${redirectUri}&scope=openid%20email%20profile&access_type=offline&prompt=consent`;
        window.location.href = authUrl;
    };

    return (
        <div>
        <BrowserView>
        <div className="backGround">
            <div className="margin"></div>
            <Reveal>
                <div className="login-container">
                    <img src={logo} alt="Recipe Chat Bot Logo" className="logo" />
                    <div className='title'> 공감 식당</div>
                    <div className="left-align">아이디</div>
                    <input type="text" placeholder="아이디를 입력하세요" value={username} onChange={(e) => setUsername(e.target.value)} />
                    <div className="left-align">비밀번호</div>
                    <input type="password" placeholder="비밀번호를 입력하세요" value={password} onChange={(e) => setPassword(e.target.value)} />
                    <button onClick={handleSignIn}>로그인</button>
                    <div className='pass'>
                        <Link to='/Password' className='pass_p' onClick={p_find}>비밀번호 찾기</Link>
                        |
                        <Link to='/Create' className='pass_c' onClick={c_account}>회원가입</Link>
                        |
                        <Link to='/Confirm' className='pass_e ' onClick={e_account}>메일 인증</Link>
                    </div>
                    <div className='google'>
                        <img src='./google2.png' width='70%' height='50px' alt='Google Login' style={{ cursor: 'pointer' }} onClick={social_google} className='g_img'/>
                    </div>
                </div>
                <div className="margin-bot"></div>
            </Reveal>
        </div>
        </BrowserView>
        <MobileView>
        <Reveal>
            <div className='margin_mobile'/>
                <div className="login-container_mobile">
            <img src={logo} alt="Recipe Chat Bot Logo" className='logo_mobile'/>
            <div className='title_mobile'> 공감 식당</div>
            <div className="left-align">아이디</div>
            <input type="text" placeholder="아이디를 입력하세요" value={username} onChange={(e) => setUsername(e.target.value)} />
            <div className="left-align">비밀번호</div>
            <input type="password" placeholder="비밀번호를 입력하세요" value={password} onChange={(e) => setPassword(e.target.value)} />
            <button onClick={handleSignIn}>로그인</button>
            <div className='pass_mobile'>
                <Link to='/Password' className='pass_p_mobile' onClick={p_find}>비밀번호 찾기</Link>
                |
                <Link to='/Create' className='pass_c_mobile' onClick={c_account}>회원가입</Link>
                |
                <Link to='/Confirm' className='pass_e_mobile' onClick={e_account}>메일 인증</Link>
            </div>
            <div className='google'>
                <img src='./google2.png' width='70%' height='50px' alt='Google Login' style={{ cursor: 'pointer' }} onClick={social_google} className='g_img'/>
            </div>
        </div>
        {/* <div className="margin-bot"></div> */}
        </Reveal>
        </MobileView>
        </div>
    );
};

export default Login;
